import clsx from 'clsx'
import { type ReactNode, useEffect, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { ClientOnly } from '~/utils/client-only'

export type TooltipProps = {
	tooltip: string
	disabled: boolean
	children: ReactNode | Array<ReactNode>
	gapSize?: number
}

export default function Tooltip({
	tooltip,
	disabled,
	children,
	gapSize = 0.5
}: TooltipProps) {
	const [isHovered, setIsHovered] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [positioned, setPositioned] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setPositioned(true)
		}, 50)
	}, [])

	return (
		<ClientOnly>
			<Popover
				isOpen={
					((isHovered || isFocused) && !!tooltip && !disabled) ||
					!positioned
				}
				positions={['top', 'bottom']}
				align='center'
				reposition={true}
				containerClassName={clsx('relative z-tooltip min-w-6')}
				padding={gapSize * 16}
				content={({ position, childRect, popoverRect }) => (
					<div className={clsx(!positioned && 'opacity-0')}>
						<ArrowContainer
							position={position}
							childRect={childRect}
							popoverRect={popoverRect}
							arrowColor={'#F2F4F8'}
							arrowSize={4}
						>
							<div
								className={clsx(
									'flex h-auto max-w-72 items-center bg-background-inverse px-4 py-[3px] text-[14px] leading-[18px] text-text-inverse'
								)}
							>
								{tooltip}
							</div>
						</ArrowContainer>
					</div>
				)}
			>
				<div>
					<div
						onPointerEnter={() => setIsHovered(true)}
						onPointerLeave={() => setIsHovered(false)}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
					>
						{children}
					</div>
				</div>
			</Popover>
		</ClientOnly>
	)
}
