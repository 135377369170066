import { clsx } from 'clsx'
import { type MouseEvent } from 'react'
import Tooltip from './tooltip'
import * as headless from '@headlessui/react'

export type ButtonStyle = 'primary' | 'secondary' | 'danger' | 'ghost'

export type ButtonSize = 'small' | 'medium' | 'large'

export type ButtonProps = {
	children?: React.ReactNode
	className?: string
	rootClassName?: string
	as?: 'button' | 'div' | 'a'

	disabled?: boolean
	focusable?: boolean
	hoverable?: boolean

	icon?: React.ReactNode
	iconOnly?: boolean

	$style: ButtonStyle
	$size: ButtonSize
	fullWidth?: boolean
	autoFocus?: boolean

	href?: string
	attributes?: React.ButtonHTMLAttributes<HTMLButtonElement>

	onClick?: (event: MouseEvent<HTMLButtonElement>) => void
	onPointerDown?: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function Button({
	children,
	className,
	rootClassName,
	as: asTag = 'button',
	disabled = false,
	focusable = true,
	hoverable = true,
	icon,
	iconOnly,
	$style = 'primary',
	$size = 'medium',
	fullWidth,
	href,
	attributes,
	onClick,
	autoFocus,
	onPointerDown
}: ButtonProps) {
	const Component = asTag

	const backgroundStyles = (interactions: boolean) => clsx(
		$style === 'primary' && {
			'bg-button-primary': true,
			'hover:bg-button-primary-hover': interactions,
			'group-hover:bg-button-primary-hover': interactions,
			'active:bg-button-primary-active': interactions,
			'group-active:bg-button-primary-active': interactions,
			'!bg-button-disabled !text-icon-on-color-disabled': disabled
		},
		$style === 'secondary' && {
			'bg-button-secondary': true,
			'hover:bg-button-secondary-hover': interactions,
			'group-hover:bg-button-secondary-hover': interactions,
			'active:bg-button-secondary-active': interactions,
			'group-active:bg-button-secondary-active': interactions,
			'!bg-button-disabled !text-icon-on-color-disabled': disabled
		},
		$style === 'danger' && {
			'bg-button-danger': true,
			'hover:bg-button-danger-hover': interactions,
			'group-hover:bg-button-danger-hover': interactions,
			'active:bg-button-danger-active': interactions,
			'group-active:bg-button-danger-active': interactions,
			'!bg-button-disabled !text-icon-on-color-disabled': disabled
		},
		$style === 'ghost' && {
			'text-link-primary': true,
			'hover:text-link-primary-hover': interactions,
			'active:text-link-primary-active': interactions,
			'bg-button-ghost': true,
			'hover:bg-button-ghost-hover': interactions,
			'group-hover:bg-button-ghost-hover': interactions,
			'active:bg-button-ghost-active': interactions,
			'group-active:bg-button-ghost-active': interactions,
			'!bg-[transparent] !text-button-disabled': disabled,
			'[&:not(:focus)_>_div]:!bg-[transparent]': interactions && !disabled
		},
	)

	const buttonStyle = clsx(
		'relative group text-text-primary p-[0.3125rem]',
		focusable && 'ring-border-interactive ring-inset !outline-none focus:ring-3 focus-visible:ring-3',
		fullWidth && 'w-full text-left justify-start items-start',
		disabled && '!cursor-not-allowed',

		$size === 'small' && `h-8`,
		$size === 'medium' && `h-12`,
		$size === 'large' && `h-16`,


		($style === 'ghost' && (hoverable && !disabled)) && 'group-hover:text-link-primary-hover hover:text-link-primary-hover',

		'focus:!bg-[transparent] focus-visible:!bg-[transparent]',
		backgroundStyles(hoverable && !disabled),
		rootClassName
	)

	const contentStyle = clsx(
		`flex text-base font-normal w-full h-full whitespace-nowrap gap-2 pointer-events-none`,
		$size === 'small' && `px-[0.6875rem] items-center justify-center`,
		$size === 'medium' && `px-[0.6875rem] items-center justify-center`,
		$size === 'large' && `px-[0.6875rem] pt-[11px] items-start justify-start`,
		iconOnly && '!px-[0.5625rem]',
		fullWidth && 'w-full !justify-start',
		disabled && '[&_svg]:!text-icon-disabled !cursor-not-allowed',
		backgroundStyles(hoverable && !disabled),
		className
	)

	return (
		<headless.Button
			autoFocus={autoFocus}
			className={buttonStyle + ''}
			disabled={disabled}
			{...(onClick ? { onClick } : {})}
			{...(onPointerDown ? { onPointerDown } : {})}
			{...(href ? { href } : {})}
			{...attributes}
		>
			<div className={contentStyle}>
				{!iconOnly && children}
				{icon}
			</div>
		</headless.Button>
	)
}

export type TooltipButtonProps = ButtonProps & {
	tooltip: string
	tooltopIconOnly?: boolean
}

export function TooltipButton({
	children,
	tooltip,
	...props
}: TooltipButtonProps) {
	return (
		<Tooltip tooltip={tooltip} disabled={props.disabled ?? false} gapSize={0.5}>
			<Button {...props} icon={props.icon}>
				{children}
			</Button>
		</Tooltip>
	)
}